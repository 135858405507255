
@import "./constants.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Roboto';
}

.app {
    display: flex;
    height: 100vh;
}

@keyframes cursor {
    0%      { background: inherit; }
    50%     { background: #FDFDFF; }
    100%    { background: inherit; }
}

@keyframes lightSpeedInLeft {
    from {
        transform: translate3d(-100%, 0, 0) skewX(30deg);
        opacity: 0;
    }

    60% {
        transform: skewX(-20deg);
        opacity: 1;
    }

    80% {
        transform: skewX(5deg);
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.center {
    margin: auto;
    text-align: center;
    z-index: 100;

    .hidden-text {
        display: flex;
        font-size: 9vw;
        font-weight: bold;
        margin: auto 0;
        visibility: hidden;
        height: 0px;

        .left {
            padding-right: 1.5vw;
        }
    }

    .hero {
        display: flex;
        font-size: 9vw;
        font-weight: bold;
        color: #FDFDFF;

        .spacer {
            display: flex;
            position: relative;

            .regular-text {
                display: flex;

                .cursor {
                    display: block;
                    animation: cursor 0.8s infinite;
                    width: 1.2vw;
                    height: 1em;
                    margin: auto 0;
                }
            }

            .bracket-end {
                margin-left: auto;
            }
        }
    }
    
    .soon-tm {
        font-size: 7vw;
        font-weight: bold;
        color: $yellow;
        opacity: 0;
        
        &.animate {
            animation: lightSpeedInLeft 0.6s ease-out 0.7s forwards;
        }
    }
}

.background {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 0;
}

